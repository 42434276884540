import	LANGUAGE				from '../ressources/language'

import	img_iconPatient			from '../ressources/images/icon/icon-patient.png'
import	img_iconValid			from '../ressources/images/icon/Check-DP.png'
import	img_iconAdd				from '../ressources/images/icon/icon-add-dark.png'
import	img_iconAddProfil		from '../ressources/images/icon/icon-add-profil.png'
import	img_men					from '../ressources/images/icon/icon-men.png'
import	img_2men				from '../ressources/images/icon/icon-men2.png'
import	img_alarm				from '../ressources/images/icon/icon-alarm.png'
import	img_rondValid			from '../ressources/images/global/rond-valid.png'
import	img_pin					from '../ressources/images/icon/pin.png'

import	img_kid					from '../ressources/images/icon/kid.png'

import	img_calendar			from '../ressources/images/icon/icon-calendrier.png'
import	img_phone				from '../ressources/images/icon/icon-phone-office.png'
import	img_medicine			from '../ressources/images/icon/medicine.png'
import	img_stethoscope			from '../ressources/images/icon/stethoscope.png'

import	img_gift				from '../ressources/images/icon/gift.png'
import	img_encaissement		from '../ressources/images/icon/encaissement-1.png'
import	img_encaissement2		from '../ressources/images/icon/encaissement-2.png'
import	img_search				from '../ressources/images/icon/icon-search.png'
import	img_espece				from '../ressources/images/icon/icon-encaissement-espece.png'
import	img_cheque				from '../ressources/images/icon/icon-encaissement-cheque.png'
import	img_cb					from '../ressources/images/icon/icon-encaissement-cb.png'
import	img_capsule				from '../ressources/images/icon/capsules.png'


import './patientMenu.css'


function	PatientMenu(props) {
	const	onClick = (isValid, error2) => {
		if (props.step8 <= 3)
			return props.set_error('2product')

		if (props.step8 >= 5) {
			if (isValid)
				return props.set_step(9)
			if (error2)
				return props.set_error('remise')
			props.set_error('paiement')
		}
	}

	return (
		<div className={'computerPatient'}>
			<div className={`computerPatientHeader ${(props.step > 2 && props.step < 8 && props.step !== 10) && 'green'} ${(props.step >= 8  && props.step !== 10) && 'blue'}`}>
				<img src={props.step >= 3 ? img_kid : img_iconPatient} alt={'patient'} />
				<span>{(props.step > 2 && props.step !== 10) ? LANGUAGE[props.lang].computer.patient.name : LANGUAGE[props.lang].computer.patient.notFound}</span>

				{(props.step <= 2 || props.step === 10) &&
					<div className={'computerPatientHeaderEmpty'}>
						<img src={img_iconAdd} alt={'add'} />
					</div>
				}
			</div>

			{(props.step < 3) &&
				<div className={'computerPatientInfoEmpty'}>
					<img src={img_iconAddProfil} alt={'add'} />
					<span>{LANGUAGE[props.lang].computer.patient.add}</span>
				</div>
			}

			{(props.step >=3 && props.step !== 10) &&
				<div className={'computerPatientInfo'}>
					<div className={'computerPatientInfoAge'}>
						<span>{LANGUAGE[props.lang].computer.patient.age}</span>
						<span>{LANGUAGE[props.lang].computer.patient.code}</span>
					</div>

					<div className={'computerPatientInfoAddress'}>
						<div>
							<img src={img_pin} alt={''} />
							<span>{LANGUAGE[props.lang].computer.patient.address}</span>
						</div>
						<span>{LANGUAGE[props.lang].computer.patient.phone}</span>
						<span>{LANGUAGE[props.lang].computer.patient.mail}</span>
					</div>

					{(props.step < 5 || props.step >= 7) &&
						<div className={'computerPatientInfoPrice'}>
							<div>
								<div>
									<img src={img_men} alt={'men'} />
								</div>
								<span>{'0,00 €'}</span>
							</div>
							<div>
								<div>
									<img src={img_2men} alt={'2men'} />
								</div>
								<span>{'0,00 €'}</span>
							</div>
						</div>
					}

					<div className={'computerPatientInfoButtonContainer'}>
						<div className={'computerPatientInfoButton'}>
							<img src={img_alarm} alt={'alarm'} />
							<span>{LANGUAGE[props.lang].computer.patient.folder}</span>
						</div>
						<div className={'computerPatientInfoDpContainer'}>
							<div className={'computerPatientInfoDp'}>
								<div>
									<img src={img_iconValid} alt={''} />
									<div />
									<img src={img_iconAdd} alt={''} />
								</div>
								<span>{LANGUAGE[props.lang].computer.patient.dp}</span>
							</div>
						</div>
					</div>

					<div className={'computerPatientInfoLine'} />

					{props.step < 5 &&
						<div>
							<span className={'computerPatientInfoTitle'}>{LANGUAGE[props.lang].computer.patient.control}</span>

							<span className={'computerPatientInfoText'}>{LANGUAGE[props.lang].computer.patient.organisme}</span>

							<div className={'computerPatientInfoCard'}>
								<div>
									<span>{LANGUAGE[props.lang].computer.patient.cpam}</span>
								</div>
								<div>
									{/* <img src={img_2men} /> */}
								</div>
							</div>

							<div className={'computerPatientInfoRight'}>
								<div>
									<span>{LANGUAGE[props.lang].computer.patient.noExoneration}</span>
									<div className={'computerPatientInfoSwitch'}>
										<div></div>
									</div>
								</div>
								<div>
									<span>{LANGUAGE[props.lang].computer.patient.openRight}</span>
									<div className={'computerPatientInfoValid'}>
										<img src={img_rondValid} alt={''} />
									</div>
								</div>
							</div>
						</div>
					}

					{(props.step >= 5 && props.step < 7) &&
						<div>
							<span className={'computerPatientInfoTitle'}>{LANGUAGE[props.lang].computer.patient.deliveryControl}</span>

							<div className={'computerPatientDate'}>
								<div>
									<span>{LANGUAGE[props.lang].computer.patient.precriptionDate}</span>
									<div className={'computerPatientDateInput'}>
										<div>{'13/06/2022'}</div>
										<div><img src={img_calendar} alt={'calendar'} /></div>
									</div>
								</div>
								<div>
									<span>{LANGUAGE[props.lang].computer.patient.deliveryDate}</span>
									<div className={'computerPatientDateInput'}>
										<div>{'15/09/2022'}</div>
										<div><img src={img_calendar} alt={'calendar'} /></div>
									</div>
								</div>
							</div>


							<div className={'computerPatientInfoDoctor'}>
								<span className={'computerPatientInfoText'}>{LANGUAGE[props.lang].computer.patient.prescripteur}</span>
								<div>
									<div>
										<img src={img_stethoscope} alt={''} />
										<span>{LANGUAGE[props.lang].computer.patient.doctorName}</span>
									</div>
								</div>
								<div>
									<div>
										<img src={img_medicine} alt={''} />
										<span>{LANGUAGE[props.lang].computer.patient.doctorInfo}</span>
									</div>
									<div>
										<img src={img_phone} alt={''} />
									</div>
								</div>
							</div>
						</div>
					}

					{props.step === 7 &&
						<div>
							<span className={'computerPatientInfoTitle'}>{LANGUAGE[props.lang].computer.patient.detail}</span>

							<div className={'computerPatientDateFlex'}>
								<div>
									<span>{LANGUAGE[props.lang].computer.patient.precriptionDate}</span>
									<div className={'computerPatientDateInput'} onClick={() => props.set_error('step7Date')}>
										<div>{'13/06/2022'}</div>
										<div><img src={img_calendar} alt={'calendar'} /></div>
									</div>
								</div>
								<div>
									<span>{LANGUAGE[props.lang].computer.patient.deliveryDate}</span>
									<div className={'computerPatientDateInput'} onClick={() => props.set_error('step7Date')}>
										<div>{'15/09/2022'}</div>
										<div><img src={img_calendar} alt={'calendar'} /></div>
									</div>
								</div>
							</div>


							<div className={'computerPatientInfoDoctor'}>
								<span className={'computerPatientInfoText'} style={{fontWeight: 'normal'}}>{LANGUAGE[props.lang].computer.patient.prescripteur}</span>
								<div>
									<div>
										<img src={img_stethoscope} alt={''} />
										<span>{LANGUAGE[props.lang].computer.patient.doctorName}</span>
									</div>
								</div>
							</div>

							<span className={'computerPatientInfoText'}>{LANGUAGE[props.lang].computer.patient.billing}</span>

							<div className={'computerPatientInfoCardStep7'}>
								<div>
									<span>{LANGUAGE[props.lang].computer.patient.cpam}</span>
								</div>
								<div>
									{/* <img src={img_2men} /> */}
								</div>
								<div>{'1,68€'}</div>
							</div>
						</div>
					}

					{props.step === 8 &&
						<div className={'computerMenuStep8'}>
							<div className={'computerMenuStep8Title'}>
								<img src={img_gift} alt={''} />
								<div>{LANGUAGE[props.lang].computer.patient.fidelite}</div>
							</div>

							<div className={'computerMenuStep8SearchContainer'}>
								<div><img src={img_search} alt={''} /></div>
								<div>{LANGUAGE[props.lang].computer.patient.search}</div>
							</div>

							<div className={'computerMenuStep8Title blue'}>
								<img src={img_encaissement} alt={''} />
								<div>{LANGUAGE[props.lang].computer.patient.encaissement}</div>
								<div className={'computerMenuStep8TitleLast'}>{<img src={img_encaissement2} alt={''} />}</div>
							</div>

							<div className={'computerMenuStep8SearchContainer noBorder'} />

							<div className={'computerMenuStep8ButtonContainer'}>
								<div onClick={() => onClick()}><img src={img_espece} alt={''} /></div>
								<div className={`computerMenuStep8Button ${props.step8 === 6 && 'shadow'}`} onClick={() => onClick(true)}><img src={img_cb} alt={''} /></div>
								<div onClick={() => onClick()}><img src={img_cheque} alt={''} /></div>
								<div onClick={() => onClick()}><img src={img_capsule} alt={''} /></div>
							</div>

							<div className={'computerMenuStep8ButtonAdd'} onClick={() => onClick(false, true)}>
								<span>{LANGUAGE[props.lang].computer.patient.addRemise}</span>
							</div>

							<div className={'computerMenuStep8Total'}>
								<span>{LANGUAGE[props.lang].computer.total.toUpperCase()}</span>
								<span>{props.total}{LANGUAGE[props.lang].computer.money}</span>
							</div>
						</div>
					}
				</div>
			}
		</div>
	)
}

export default PatientMenu