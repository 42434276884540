import { useState, useEffect, useRef }	from 'react'
import ReactHowler 					from 'react-howler'
import Computer						from './Computer'
import Legal						from './Legal'

import	LANGUAGE					from './ressources/language'

import img_background				from './ressources/images/background/background.jpg'
import img_table					from './ressources/images/background/table.png'
import img_computer					from './ressources/images/background/computer.png'

import img_homeText					from './ressources/images/background/homeText.png'

import img_sound_on					from './ressources/images/icon/sound_on.png'
import img_sound_off				from './ressources/images/icon/sound_off.png'
import img_replay					from './ressources/images/icon/replay.png'
import img_logo						from './ressources/images/global/logo-SmartRx.png'
import img_introButtonIcon			from './ressources/images/global/intro-button-icon.png'
import img_logoNev					from './ressources/images/visu/logo-smart-rx.png'

import img_box						from './ressources/images/visu/box.png'
import img_cartevitale				from './ressources/images/visu/object-cartevitale.png'
import img_ordonance				from './ressources/images/visu/table-ordonnance.png'
import img_rondValid				from './ressources/images/global/rond-valid.png'
import img_scanner					from './ressources/images/visu/scanner.png'

import img_ointment					from './ressources/images/visu/ointment-resize.png'
import img_ointmentShadow			from './ressources/images/visu/ointment-light.png'
import img_creditcard				from './ressources/images/visu/creditcard.png'
import img_creditcardShadow			from './ressources/images/visu/creditcard-shadow.png'
import img_sunscreen				from './ressources/images/visu/sunscreen-resize.png'
import img_sunscreenShadow			from './ressources/images/visu/sunscreen-light.png'

import img_popup_collapse			from './ressources/images/pop-up-final/icon fleche.png'

import img_final					from './ressources/images/global/final.png'

import music						from './ressources/audio/Loopable.mp3'

import video_intro					from './ressources/video/webinaires.mp4'

import './App.css'


function App() {
	function	setLocalStorage(key, value) {localStorage.setItem(key, JSON.stringify(value))}
	function	getLocalStorage(key, base) {try {return JSON.parse(localStorage.getItem(key) || base)} catch (e) {return base}}

	const	video = useRef()
	const	[zoom, set_zoom] = useState(0)
	const	[step, set_step] = useState(1)
	const	[stepComputer, set_stepComputer] = useState(0)
	const	[lang, set_lang] = useState('fr')
	const	[play, set_play] = useState(false)
	const	[playVideo, set_playVideo] = useState(false)

	const	[step1, set_step1] = useState(0)
	const	[step4, set_step4] = useState(0)
	const	[step10, set_step10] = useState(0)

	
	const	[drawComputer, set_drawComputer] = useState(false)
	
	const	[hoverOrdo, set_hoverOrdo] = useState(false)
	const	[clickBox, set_clickBox] = useState(false)
	const	[clickCv, set_clickCv] = useState(false)
	const	[clickOrdo, set_clickOrdo] = useState(false)
	
	const	[stepComputer8, set_stepComputer8] = useState(1)
	const	[clickCb, set_clickCb] = useState(false)

	const	[sound, set_sound] = useState(true)
	const	[reset, set_reset] = useState(false)

	const	[legal, set_legal] = useState(false)
	const	[cookie, set_cookie] = useState(getLocalStorage('cookie', null) || false)

	useEffect(() => {
		set_stepComputer(step - 4)

		if (step === 3 && !drawComputer)
			setTimeout(() => set_drawComputer(true), 1200)
	}, [step])

	useEffect(() => {
		if (reset)
			set_reset(false)
	}, [reset])

	useEffect(() => {
		if (stepComputer8 === 2)
			set_stepComputer8(3)
	}, [stepComputer8])

	function	setCookie() {
		set_cookie(true)
		setLocalStorage('cookie', true)
	}

	function renderStep() {
		const	onClick = () => {
			set_zoom(zoom + 1)
			set_step(step + 1)
		}

		if (step === 1) {
			return (
				<div className={'bodyContainer'}>
					<div className={'step1'}>
						{/* <span>{LANGUAGE[lang].step1.text1}</span>
						<span>{LANGUAGE[lang].step1.text2}</span> */}
						<img src={img_homeText} alt={''} />
					</div>
				</div>
			)
		} else if (step === 2 || step === 3) {
			return (
				<div className={`bodyContainer ${step === 3 && 'end'}`}>
					<div className={'step2'}>
						<div>
							<span>
								<img src={img_logoNev} alt={'NeV'} />{','}
								<br />
								{LANGUAGE[lang][step === 2 ? 'step2' : 'step3'].text2}
								<br />
								<span>{LANGUAGE[lang][step === 2 ? 'step2' : 'step3'].text3}</span>
							</span>
							<div />
						</div>
						<div onClick={onClick} />
					</div>
				</div>
			)
		} else if (step === 4 && step4 < 2) {
			return (
				<div className={`bodyContainer spaceBetween`}>
					<div className={'step4 first'}>
						<div>
							<span>
								{LANGUAGE[lang].step4.text1}<br />
								<span>
									{LANGUAGE[lang].step4.text2}
									<div style={{marginTop: 10}} />
									<img src={img_logoNev} alt={'NeV'} />
								</span>
							</span>
							<div />
						</div>
						<div style={step4 === 1 ? {visibility: 'hidden'} : {}} onClick={() => set_step4(1)} />
					</div>

					{step4 === 1 &&
						<div className={'step4 last'}>
							<div>
								<span>
									{LANGUAGE[lang].step4.text3}<br />
									{LANGUAGE[lang].step4.text4}<br />
									<span>{LANGUAGE[lang].step4.text5}</span>
								</span>
								<div />
							</div>

							<div>
								{/* <img alt={''} /> */}
								<div className={'step4Button'} onClick={() => {
									set_step4(2)
									set_zoom(zoom + 1)
								}}>
									<span>{LANGUAGE[lang].step4.button}</span>
									<div>
										<img src={img_introButtonIcon} />
									</div>
								</div>
							</div>
						</div>
					}
				</div>
			)
		}

		if (step >= 5 && step <= 11) {
			return (
				<div className={`bodyContainer full`}>
					<div className={'step5'}>
						<div>
							<div className={`step5Box ${(step === 10 && !clickBox) && 'active'}`} onClick={() => (step === 10 && !clickBox) && set_clickBox(true)}>
								<img src={img_box} alt={''} />
								{clickBox &&
									<div>
										<img src={img_rondValid} alt={''} />
									</div>
								}
							</div>
							<div className={`step5Carte ${(step === 5 && !clickCv) && 'active'}`} onClick={() => {
								if (step === 5 && !clickCv) {
									set_clickCv(true)
									set_step1(1)
								}
							}}>
								<img src={img_cartevitale} alt={''} />
								{clickCv &&
									<div>
										<img src={img_rondValid} alt={''} />
									</div>
								}
							</div>
						</div>
						<div>
							<div className={`step5Ordonance ${(step === 7 && !clickOrdo) && 'active'}`} onClick={() => (step === 7 && !clickOrdo) && set_clickOrdo(true)}>
								<img src={img_ordonance} alt={''} onMouseEnter={() => set_hoverOrdo(true)} onMouseLeave={() => set_hoverOrdo(false)} />

								{step >= 8 &&
									<div>
										<img src={img_rondValid} alt={''} />
									</div>
								}
							</div>
						</div>
					</div>

					<div className={'step5Scanner'}>
						<img src={img_scanner} alt={''} />
					</div>
				</div>
			)	
		}
		if (step > 11) {
			return (
				<div className={`bodyContainer full`}>
					{(step === 12 || step === 13) ?
						<div className={'step11'}>
							{/* <div>
								<div className={`step11item ${(step === 12 && stepComputer8 === 3) && 'active'}`} onClick={() => (step === 12 && stepComputer8 === 3) && set_stepComputer8(4)}>
									{stepComputer8 >= 5 &&
										<div>
											<img src={img_sunscreen} alt={''} />
										</div>
									}
									{stepComputer8 >= 5 &&
										<div>
											<img src={img_sunscreen} alt={''} />
										</div>
									}
									<img src={(step === 12 && stepComputer8 === 3) ? img_sunscreenShadow : img_sunscreen} alt={''} style={{zIndex: 20}} />
								</div>
								<div className={`step11item ${(step === 12 && stepComputer8 === 1) && 'active'}`} onClick={() => (step === 12 && stepComputer8 === 1) && set_stepComputer8(3)}>
									<img src={(step === 12 && stepComputer8 === 1) ? img_ointmentShadow : img_sunscreen} alt={''} />
								</div>
							</div> */}
							<div>
								<div className={`step11Card ${(step === 13 && !clickCb) && 'active'}`} onClick={() => (step === 13 && !clickCb) && set_clickCb(true)}>
									<img src={(step === 13 && !clickCb) ? img_creditcardShadow : img_creditcard} alt={''} />
								</div>
							</div>
						</div>
						:
						<div />
					}
					{(step === 12 || step === 13) &&
						<div className={'step11itemContainer'}>
							<div>
								<div className={`step11item ${(step === 12 && stepComputer8 === 3) && 'active'}`} onClick={() => (step === 12 && stepComputer8 === 3) && set_stepComputer8(4)}>
									{stepComputer8 >= 5 &&
										<div>
											<img src={img_sunscreen} alt={''} />
										</div>
									}
									{stepComputer8 >= 5 &&
										<div>
											<img src={img_sunscreen} alt={''} />
										</div>
									}
									<img src={(step === 12 && stepComputer8 === 3) ? img_sunscreenShadow : img_sunscreen} alt={''} style={{zIndex: 20}} />
								</div>
								<div className={`step11item ${(step === 12 && stepComputer8 === 1) && 'active'}`} onClick={() => (step === 12 && stepComputer8 === 1) && set_stepComputer8(3)}>
									<img src={(step === 12 && stepComputer8 === 1) ? img_ointmentShadow : img_ointment} alt={''} />
								</div>
							</div>
						</div>
					}
					<div className={'step5Scanner'}>
						<img src={img_scanner} alt={''} />
					</div>
				</div>
			)
		}
	}

	if (window.location.pathname === '/form')
		return <div className={'appForm'}><img src={img_final} alt={''} /></div>

	const	body = document.getElementById('app')
	const	header = document.getElementById('mainHeader')
	const	style = {}

	if (body && header) {
		style.top = header.offsetHeight
		style.height = body.offsetHeight - header.offsetHeight
	}

	return (
		<div id={'app'} className="App" onClick={() => set_play(true)}>
			{!reset &&
				<ReactHowler src={music} playing={play && sound && !(step === 4 && step4 === 2)} loop={true} />
			}

			<div className={'background'} style={{backgroundImage: `url(${img_background})`}} >
				<div />
			</div>

			<div className={'tableContainer'}>
				<div className={`table zoom${zoom}`} style={{backgroundImage: `url(${img_table})`}} />

				{/* <img className={'computer'} src={img_computer} /> */}

				<div className={`computer zoom${zoom}`} style={{backgroundImage: `url(${img_computer})`}}>
					{(drawComputer) &&
						<div id={'computerZone'} className={`computerZone zoom${zoom}`}>
							<Computer
								lang={lang}
								step={stepComputer}
								set_step={val => set_step(val + 4)}

								step1={step1}
								step8={stepComputer8}
								set_step8={set_stepComputer8}

								clickOrdo={clickOrdo}
								clickBox={clickBox}
								clickCb={clickCb}

								style={(step4 === 2 && step === 4) ? {visibility: 'hidden'} : {}}
							/>

							{stepComputer === 10 &&
								<div className={'step10Modal'}>
									{step10 === 0 &&
										<div>
											<span style={{fontSize: 30}}>{LANGUAGE[lang].computer.step10.bravo}</span>
											<br />
											<span>
												{LANGUAGE[lang].computer.step10.text1}
												<img src={img_logoNev} alt={'NEV'} /><br />
												{LANGUAGE[lang].computer.step10.text2}
											</span>

											<br />
											<a href={'https://smartrealxperience-inscription.fr/'} target={'_blank'} className={'step10Button'}>
												<div>
													<span>{LANGUAGE[lang].computer.step10.webinaire}</span>
													<img src={img_popup_collapse} alt={''} />
												</div>
											</a>

											{/* <div className={'step10Arrow'} onClick={() => set_step10(1)}>
												<img src={img_popup_collapse} alt={'>'} />
											</div> */}

											<div className={'step10Pourcent'}>
												<div>
													<div>
														<span>{'15'}<span>{'%'}</span></span>
													</div>
												</div>
												<div />
											</div>
										</div>
									}
									{step10 === 1 &&
										<div>
											<span>{LANGUAGE[lang].computer.step10.text2}</span>
											<br />
											<a href={'https://smartrealxperience-inscription.fr/'} target={'_blank'} className={'step10Button'}>
												<div>
													<span>{LANGUAGE[lang].computer.step10.webinaire}</span>
													<img src={img_popup_collapse} alt={''} />
												</div>
											</a>
										</div>
									}
								</div>
							}
						</div>
					}
					{step === 1 &&
						<div id={'computerZone'} className={'computerZone zoom2'}>
							<div className={'step1Round'} onClick={() => {
								set_step(step + 1)
								set_zoom(zoom + 1)
							}} />
						</div>
					}
				</div>
			</div>

			<div className={'mainContainer'}>
				<div id={'mainHeader'} className={'header'}>
					<div className={'headerSound'}>
						<div className={'headerSoundBar'} />
						<img src={sound ? img_sound_on : img_sound_off} onClick={() => set_sound(!sound)} />
						{/* <img src={img_replay} onClick={() => set_reset(true)} /> */}
						<img src={img_replay} onClick={() => window.location.reload()} />
					</div>
					<div className={'headerText'}>
						Smart Real Xperience
					</div>
					<div className={'headerLogo'}>
						<div className={'headerLink'} onClick={() => set_legal(true)}>{LANGUAGE[lang].header.about}</div>
						<a href={'https://www.smart-rx.com/'} target={'_blank'}><img src={img_logo} /></a>
					</div>
				</div>

				{(step === 4 && step4 === 2) &&
					<video
						ref={video}
						// muted
						controls
						// autoPlay
						style={style}
						src={video_intro}
						onPlay={() => set_playVideo(true)}
						onPause={() => set_playVideo(false)}
						onEnded={() => set_step(5)}
						onClick={() => {console.log('Ici');video.current.play()}} />
				}

				{renderStep()}
			</div>

			{hoverOrdo && <img className={`step5OrdonanceHover ${(step === 7 && !clickOrdo) && 'active'}`} onClick={() => (step === 7 && !clickOrdo) && set_clickOrdo(true)} src={img_ordonance} alt={''} onMouseEnter={() => set_hoverOrdo(true)} onMouseLeave={() => set_hoverOrdo(false)} />}

			{!cookie &&
				<div className={'appModal'}>
					<div className={'computerInfoPopup messageCookie'}>
						{LANGUAGE[lang].cookie.text}

						<div>
							<div>
								<div className={'step4Button'} onClick={() => setCookie()}>
									<span>{LANGUAGE[lang].cookie.accept}</span>
									{/* <div>
										<img src={img_introButtonIcon} />
									</div> */}
								</div>
							</div>
							{'  '}
							<div>
								<div className={'step4Button'} onClick={() => setCookie()}>
									<span>{LANGUAGE[lang].cookie.deny}</span>
									{/* <div>
										<img src={img_introButtonIcon} />
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			}

			{legal && <Legal close={() => set_legal(false)} />}

			{(!playVideo && step === 4 && step4 === 2) &&
					<div className='playButton' onClick={() => {console.log('Ici');video.current.play()}} />
				}
		</div>
	);
}

export default App;
