const	text = {
	computer: {
		total:			'Total',
		money:			'€',

		header: {
			search:			'Rechercher un patient',
			searchStep5:	'Rechercher nom ; RPPS ; FINESS ; Ville ; Spécialité ; raison sociale',
			searchStep6:	'Ajouter un produit',
			searchStep8:	'Ajouter un produit'
		},
		menu: {
			comptoir:	'COMPTOIR',
			ordonnance:	'ORDONNANCE',
			customer:	'Fiche Client',
			therapeutique:	'LIGNES THERAPEUTIQUES',
			medecin:		'Médecin'
		},
		patient: {
			notFound:		'CLIENT NON IDENTIFIÉ',
			add:			'Créer un nouveau client',
			name:			'Nina PRESTO',
			age:			'8 ans',
			code:			'1 85 05 79 055 014 09',
			address:		'79000 Bessines',
			phone:			'062230481225',
			mail:			'theo.presto@gmail.com',
			folder:			'Dossier Thérapeutique',
			dp:				'DP CRÉÉ',
			control:		'CONTRÔLE DES DROITS',
			organisme:		'Organisme AMO',
			cpam:			'CPAM 79',
			noExoneration:	'Sans exonération',
			openRight:		'Droits ouverts',

			deliveryControl:	'CONTRÔLE DE DÉLIVRANCE',
			precriptionDate:	'Date prescription',
			deliveryDate:		'Date délivrance',
			prescripteur:		'Prescripteur',
			doctorName:			'DUPONT Jean',
			doctorInfo:			'Omnipraticien',

			detail:				'DETAIL DE FACTURATION',
			billing:			'Facture 1/3 Payant nº 702269',

			fidelite:			'FIDÉLITÉ',
			search:				'Rechercher une carte Fidélité',
			encaissement:		'ENCAISSEMENT',
			addRemise:			'AJOUTER UNE REMISE'
		},

		listHeader: {
			card:		'LIRE CARTE VITALE',
			scan:		'NUMÉRISER',
			keyboard:	'SAISIR PRESCRIPTION',
			sold:		'DISPENSER',
			print:		'EDITER',
			control:	'CONTRÔLE PJ SCOR'
		},

		step1: {
			title:		'Lecture de la carte vitale',
			readCard:	'Lecture Carte Vitale',
			readApCV:	'Lecture ApCV',
			popup1:		'A vous de jouer !',
			popup2:		'Réalisez la délivrance de ce touriste pressé !',
			popup3:		'Cliquez sur les éléments lumineux pour vivre l’expérience !'
		},
		step2: {
			title:		'LISTE DES BÉNÉFICIAIRES',
			name:		'Nom Prénom',
			quality:	'Qualité',
			date:		'Date de naissance',
			message:	'La prescription est pour sa fille Nina.',
			users: [
				{
					name:		'PRESTO THEO',
					quality:	'Assuré',
					date:		'14/04/1960',
				},
				{
					name:		'PRESTO NINA',
					quality:	'Enfant',
					date:		'05/10/2014',
				}
			]
		},
		step3: {
			title:			'Numérisation de l\'ordonnance',
			scan:			'Veuillez placer l\'ordonnance dans le scanner.',
			or:				'OU',
			barcode:		'Veuillez scanner le QR situé sur l\'ordonnance',
			search:			'Rechercher E-prescription',
			ocr:			'OCR activé',
			noPrescription:	'DISPENSER SANS PRESCRIPTION',
			later:			'NUMÉRISER PLUS TARD',
			read:			'NUMÉRISER'
		},
		step4: {
			previous:		'PRÉCÉDENT',
			valid:			'VALIDER',
			type:			'Type d\'ordonnance',
			clasiq:			'Ordonnance classique',
			ald:			'Ordonnance ALD',
			exception:		'Ordonnance Exception',
			grippe:			'Coupon grippe',
			other:			'Autre',
			archive:		'Archiver l\'ordonnance',
			preview:		'Aperçu'
		},
		step5: {
			fullname:			'Nom Prénom',
			speciality:			'Spécialité',
			precriptionDate:	'Date prescription',
			numero:				'Nº du renouvellement',
			number:				'Nb renouv',
			frequence:			'Fréquence de délivrance',
			day:				'Jours',
			month:				'Mois',
			error:				'Ceci n\'est pas conforme à l\'ordonnance'
		},
		step6: {
			product:		'PRODUIT',
			price:			'PRIX',
			quantity:		'QTÉ',
			amo:			'AMO',
			amc:			'AMC',
			rest:			'RESTE À CHARGE',
			billing:		'FACTURE 1/3 PAYANT',
			productName:	'DESLORATADINE 5MG S. CPR 30',
			honoraire:		'HONORAIRES DE DISPENSATION',
			error:			'Il faut scanner la boîte posée sur le comptoir.',
		},
		step7: {
			type:			'Type de documents',
			verso:			'VERSO D\'ORDONNANCE',
			soin:			'FEUILLE DE SOIN',
			billing:		'FACTURE MUTUELLE',
			ordonnance:		'ORDONNANCE D\'EXCEPTION',
			attestation:	'ATTESTATION VACCINATION',
			plan:			'PLAN DE POSOLOGIE',
			list:			'Liste d\'impressions',
			print:			'IMPRIMER',
			billingTier:	'FACTURE 1/3 PAYANT',
			pay:			'ORDONNANCE PAYANTE',
			errorAdd:		'Ces types de documents ne sont pas nécessaires ici.',
			errorDate:		'Ceci n\'est pas conforme à l\'ordonnance.',
			message:		'Le contrôle PJ Scor est automatique lorsque l\'ordonnance est numérisée.'
		},
		step8: {
			title:			'COMPTOIR',
			product:		'PRODUIT',
			price:			'PRIX',
			quantity:		'QTÉ',

			ordonnance:		'ORDONNANCE NINA PRESTO',
			rest:			'Reste à charge',
			gel:			'GEL PLAIES ET BRULURES TUBE 100G',
			creme:			'CREME MINERALE IP50 + TUBE 50ML',

			promo:			'Promo jusqu\'au 31/05/2023',
			stock:			'Stock: 10',
			promoProduct:	'PRODUIT DE LA PROMO',
			lot:			'(LOT DE 2)',

			message1:		'Quelle rapidité ! Passez maintenant au scan du gel et de la crème, ajoutés par notre touriste pressé.',
			message2:		'Identification rapide des produits éligibles à la fidélité',
			message3:		'Le client souhaite bénéficier de la promotion valable pour 2 unités. Il prend donc 2 crèmes.',
			message4:		'Promotion effectuée !',

			error1:			'Souvenez-vous, votre client a un autre produit !',
			error2:			'La promotion n\'est active que par lot de 2 !',
			error3:			'Le touriste demande à payer en carte bancaire',
			error4:			'Dans notre exemple, la remise n\'est pas possible ici !'
		},
		step9: {
			paiement:		'Règlement par carte bancaire',
			total:			'TOTAL FACTURE',
			option:			'Options d\'impression du ticket',
			cancel:			'ANNULER',
			save:			'ENREGISTRER',

			message:		'Vous pouvez désormais enregister pour finaliser la vente.'
		},
		step10: {
			bravo:			<span>{'Bravo ! '}<span style={{color: '#065C7A'}}>{'Vous êtes 15% + Smart !'}</span></span>,
			text1:			<span><span style={{color: '#065C7A'}}>{'Continuez'}</span>{' l’expérience de prise en main à distance du '}</span>,
			text2:			<span><span style={{color: '#065C7A'}}>{'avec les sessions interactives animées par Smart Rx ! '}</span><br />{'Découvrez plus de fonctionnalités, en vous inscrivant ! '}</span>,
			webinaire:		'Je m\'inscris'
		}
	},

	header: {
		about:			'A PROPOS'
	},

	step1: {
		text1:			'Entrez dans la',
		text2:			'Smart Real Xperience'
	},
	step2: {
		text:			'NeV,',
		text2:			'la facturation réinventée',
		text3:			'Disponible dès à présent !'
	},
	step3: {
		text:			'NeV,',
		text2:			'une interface de facturation',
		text3:			'Simple, rapide, intelligente'
	},
	step4: {
		text1:			'Après ces quelques clics,',
		text2:			'vous adopterez déjà l\'une des nouvelles fonctionnalités du ',
		text3:			'Fonctionnalité',
		text4:			'nº1 :',
		text5:			'La délivrance et la vente n\'ont jamais été aussi rapides !',
		button:			'Testez dès maintenant'
	},

	cookie: {
		text:			'smartrealxperience.fr utilise des cookies pour vous assurer une utilisation optimale de notre site Internet. Nous conservons votre choix pendant 6 mois. Vous pouvez consultez notre politique de cookies dans l\'onglet A PROPOS.',
		accept:			'ACCEPTER ET FERMER',
		deny:			'NON, JE N\'ACCEPTE PAS'
	}
}

export default text