import	{useState, useEffect, useRef}	from 'react'

import	LANGUAGE				from '../ressources/language'

import	img_logoSmartRx			from '../ressources/images/visu/logo-smart-rx.png'
import	img_iconPatient			from '../ressources/images/icon/icon-patient.png'
import	img_iconMan				from '../ressources/images/icon/icon-man.png'
import	img_iconSearch			from '../ressources/images/icon/icon-search.png'
import	img_iconSearch_green	from '../ressources/images/icon/icon-search-green.png'
import	img_iconClose			from '../ressources/images/icon/icon-close.png'
import	img_iconTrash			from '../ressources/images/icon/icon-trash.png'

import	img_iconComptoirBlue	from '../ressources/images/icon/icon-comptoir-blue.png'
import	img_iconComptoir		from '../ressources/images/icon/icon-comptoir-gris.png'
import	img_iconComptoirWhite	from '../ressources/images/icon/icon-comptoir-blanc.png'
import	img_iconOrdonnance		from '../ressources/images/icon/pill.png'
import	img_iconOrdonnanceGreen from '../ressources/images/icon/pill-green.png'
import	img_iconBcb				from '../ressources/images/icon/icon-bcb.jpg'

import	img_card				from '../ressources/images/icon/barre/carte-vitale-1.png'
import	img_card_green			from '../ressources/images/icon/barre/carte-vitale-2.png'
import	img_card_white			from '../ressources/images/icon/barre/card-white.png'
import	img_scan				from '../ressources/images/icon/barre/scan-01.png'
import	img_scan_green			from '../ressources/images/icon/barre/scan-02.png'
import	img_scan_white			from '../ressources/images/icon/barre/scan-white.png'
import	img_keyboard			from '../ressources/images/icon/barre/clavier-01.png'
import	img_keyboard_green		from '../ressources/images/icon/barre/clavier-02.png'
import	img_keyboard_white		from '../ressources/images/icon/barre/keyboard-white.png'
import	img_venteComptoir		from '../ressources/images/icon/barre/dispenser-01.png'
import	img_venteComptoir_green	from '../ressources/images/icon/barre/dispenser-02.png'
import	img_venteComptoir_white	from '../ressources/images/icon/barre/dispenser-white.png'
import	img_print				from '../ressources/images/icon/barre/editer-01.png'
import	img_print_green			from '../ressources/images/icon/barre/editer-02.png'
import	img_print_white			from '../ressources/images/icon/barre/editer-white.png'
import	img_control				from '../ressources/images/icon/barre/controle-01.png'
import	img_control_green		from '../ressources/images/icon/barre/controle-02.png'
import	img_control_white		from '../ressources/images/icon/barre/controle-white.png'

// import	img_cardGreen			from '../ressources/images/icon/card-green.png'
// import	img_scan				from '../ressources/images/icon/scan.png'
// import	img_keaybordHand		from '../ressources/images/icon/keaybord-hand.png'
// import	img_venteComptoir		from '../ressources/images/icon/vente-comptoir.png'
// import	img_print				from '../ressources/images/icon/print.png'
// import	img_folderAlert			from '../ressources/images/icon/folder-alert.png'
import	img_folderFirstAid		from '../ressources/images/icon/first-aid-box.png'
import	img_iconTherapeutique	from '../ressources/images/icon/icon-therapeutique.png'

import	img_visuCartevitale		from '../ressources/images/visu/visu-cartevitale.png'
import	img_visuIpad			from '../ressources/images/visu/ipad.svg'
import	img_iconAdd				from '../ressources/images/icon/icon-add-dark.png'

import	img_visuScan			from '../ressources/images/visu/visu-scan.png'
import	img_visuBarcode			from '../ressources/images/visu/barcode-150x150.png'

import	img_iconScanAdd			from '../ressources/images/icon/icon-scan-add-green.png'
import	img_iconScan			from '../ressources/images/icon/icon-scan-normal-green.png'
import	img_iconScanRefresh		from '../ressources/images/icon/icon-scan-refresh.png'
import	img_iconScanDelete		from '../ressources/images/icon/icon-scan-delete-green.png'
import	img_visuOrdonance		from '../ressources/images/visu/table-ordonnance.png'

import	img_iconListbullet		from '../ressources/images/icon/icon-listbullet.png'
import	img_stethoscope			from '../ressources/images/icon/stethoscope-gris.png'
import	img_stethoscope_white	from '../ressources/images/icon/stethoscope-blanc.png'

import	img_capsule				from '../ressources/images/icon/pill-box-gris.png'
import	img_settings			from '../ressources/images/icon/settings.png'
import	img_sandClock			from '../ressources/images/icon/sand-clock.png'

import	img_iconGift			from '../ressources/images/icon/gift.png'
import	img_iconGift_blue		from '../ressources/images/icon/icon-gift-blue.png'

import	img_promo				from '../ressources/images/icon/promo.png'

import	img_iconCb				from '../ressources/images/icon/CB-bleu.png'
import	img_iconPrint_blue		from '../ressources/images/icon/print-blue.png'
import	img_iconSave			from '../ressources/images/icon/save.png'

import	'./style.css'
import PatientMenu from './patientMenu'

function useWindowSize() {
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined
	})
	
	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			})

			setTimeout(
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight,
				})
			, 1000)
		}

		window.addEventListener("resize", handleResize)
		handleResize()
		
		return () => window.removeEventListener("resize", handleResize)
	}, [])
	
	return windowSize
}


function Computer(props) {
	const	size = useWindowSize()

	const	dateInput = useRef()
	const	[step2, set_step2] = useState(0)
	const	[step3, set_step3] = useState(0)
	const	[step5, set_step5] = useState(0)
	const	[step6, set_step6] = useState(0)
	const	[step7, set_step7] = useState(0)
	const	[step9, set_step9] = useState(0)

	const	[error, set_error] = useState(false)

	useEffect(() => set_error(false), [props.step, props.step8])

	useEffect(() => {
		if (props.step === 3 && step3 === 0 && props.clickOrdo)
			set_step3(1)
	}, [props.clickOrdo])

	useEffect(() => {
		if (props.step === 6 && step6 === 0 && props.clickBox)
			set_step6(1)
	}, [props.clickBox])
	
	useEffect(() => {
		if (props.step === 9 && step9 === 0 && props.clickCb)
			set_step9(1)
	}, [props.clickCb])

	useEffect(() => {
		if (props.step8 === 5)
			props.set_step8(6)
	}, [props.step8])

	function	renderMain() {
		const	renderItem = (src, srcGreen, srcWhite, active, actual, isFirst, isLast) => {
			if (!isFirst) {
				render.push(
					<div className={`computerContainerListHeaderLine`} >{active && <div />}</div>
				)
			}

			let	finalSrc = src

			if (actual)
				finalSrc = srcGreen
			else if (active)
				finalSrc = srcWhite

			render.push(
				<div className={`computerContainerListHeaderIcon ${active && 'active'} ${actual && 'actual'}`}>
					<img src={finalSrc} alt={'icon'} />

					{(isLast && props.step === 7 && step7 === 2) &&
						<div className={`computerStep7Message`}>
							<div>
								<div>
									<div className={'computerErrorPopup messageStep7'}>
										{LANGUAGE[props.lang].computer.step7.message}
									</div>
								</div>
							</div>
						</div>
					}
				</div>
			)
		}
		const	render = []

		if (props.step <= 7 || props.step === 10) {
			renderItem(img_card, img_card_green, img_card_white, true, props.step <= 2, true)
			renderItem(img_scan, img_scan_green, img_scan_white, props.step >= 3, props.step === 3 || props.step === 4)
			renderItem(img_keyboard, img_keyboard_green, img_keyboard_white, props.step >= 5, props.step === 5)
			renderItem(img_venteComptoir, img_venteComptoir_green, img_venteComptoir_white, props.step >= 6, props.step === 6)
			renderItem(img_print, img_print_green, img_print_white, props.step >= 7, props.step === 7)
			renderItem(img_control, img_control_green, img_control_white, props.step === 10, props.step === 10, false, true)

			const	computerZone = document.getElementById('computerZone')
			const	computerHeader = document.getElementById('computerHeader')
			const	header = document.getElementById('header')
			const	bottom = document.getElementById('bottom')
			const	style = {}

			if (computerZone && computerHeader && header && bottom)
				style.maxHeight = computerZone.offsetHeight - header.offsetHeight - header.style.marginTop - bottom.offsetHeight - computerHeader.offsetHeight

			return (
				<div className={'computerMainContainer'}>
					<div id={'header'} className={'computerContainerListHeader'}>
						<div>
							<div />
							{render}
							<div />
						</div>
						<div>
							<div style={{color: '#30BF4C'}}>{LANGUAGE[props.lang].computer.listHeader.card}</div>
							<div style={props.step >= 3 ? {color: '#30BF4C'} : {}}>{LANGUAGE[props.lang].computer.listHeader.scan}</div>
							<div style={props.step >= 5 ? {color: '#30BF4C'} : {}}>{LANGUAGE[props.lang].computer.listHeader.keyboard}</div>
							<div style={props.step >= 6 ? {color: '#30BF4C'} : {}}>{LANGUAGE[props.lang].computer.listHeader.sold}</div>
							<div style={props.step >= 7 ? {color: '#30BF4C'} : {}}>{LANGUAGE[props.lang].computer.listHeader.print}</div>
							<div style={props.step >= 10 ? {color: '#30BF4C'} : {}}>{LANGUAGE[props.lang].computer.listHeader.control}</div>
						</div>
					</div>

					{(props.step <= 1 || props.step === 10) &&
						<div className={`computerStep1 flex`}>
							<div className={'computerStep1Title'}>{LANGUAGE[props.lang].computer.step1.title}</div>
							<div className={'computerStep1Container'}>
								<div>
									<img src={img_visuCartevitale} className={`computerStep1Image ${(props.step === 1 && props.step1 === 1) && 'active'}`} alt={''} onClick={() => (props.step === 1 && props.step1 === 1) && props.set_step(2)} />
									<span>{LANGUAGE[props.lang].computer.step1.readCard}</span>
								</div>
								<div>
									<img src={img_visuIpad} alt={''} />
									<span style={{color: '#CCCCCC'}}>{LANGUAGE[props.lang].computer.step1.readApCV}</span>
								</div>
							</div>
							{(props.step === 1 && props.step1 === 0) &&
								<div className={'computerInfoPopup messageStep1'}>
									<span>{LANGUAGE[props.lang].computer.step1.popup1}</span><br /><br />
									{LANGUAGE[props.lang].computer.step1.popup2}<br />
									{LANGUAGE[props.lang].computer.step1.popup3}
								</div>
							}
						</div>
					}

					{props.step === 2 &&
						<div className={'computerStep2'}>
							<div className={'computerStep1'}>
								<div className={'computerStep1Title'}>
									{LANGUAGE[props.lang].computer.step2.title}
									<div><img src={img_iconAdd} alt={'add'} /></div>
								</div>
								
								<table>
									<thead>
										<tr>
											<th width="60%">{LANGUAGE[props.lang].computer.step2.name}</th>
											<th width="20%">{LANGUAGE[props.lang].computer.step2.quality}</th>
											<th width="20%">{LANGUAGE[props.lang].computer.step2.date}</th>
										</tr>
									</thead>
									<tbody>
										{LANGUAGE[props.lang].computer.step2.users.map((data, i) => {
											let	style = {}

											if (i === 1) {
												style.cursor = 'pointer'

												if (step2 > 0)
													style.border = '2px solid #30BF4C'
											}

											return (
												<tr style={style} onClick={() => (i === 1 && step2 === 0) && props.set_step(3)}>
													<td style={{position: 'relative'}}>
														{data.name}
														{/* {(i === 1 && step2 === 0) &&
															// <div className={`computerStep2Message`}>
															// 	<div>
															// 		<div>
																		// <div className={'computerErrorPopup messageStep7'}>
																		// 	{LANGUAGE[props.lang].computer.step2.message}
																		// </div>
															// 		</div>
															// 	</div>
															// </div>
														// } */}
														{(i === 1 && step2 === 0) &&
															<div className='computerStep2Shadow'>

															</div>
														}
													</td>
													<td>{data.quality}</td>
													<td>{data.date}</td>
												</tr>
											)
										})}
									</tbody>
								</table>
								<div className={'computerErrorPopup messageStep2'}>
									{LANGUAGE[props.lang].computer.step2.message}
								</div>
							</div>
						</div>
					}

					{props.step === 3 &&
						<div className={'computerStep1 flex'}>
							<div className={'computerStep1Title'}>{LANGUAGE[props.lang].computer.step3.title}</div>
							<div className={'computerStep3Container'}>
								<div>
									<img src={img_visuScan} className={`computerStep3Image ${(step3 === 1) && 'active'}`} alt={''} onClick={() => (step3 === 1) && props.set_step(props.step + 1)} />
									<span>{LANGUAGE[props.lang].computer.step3.scan}</span>
								</div>
								<span>{LANGUAGE[props.lang].computer.step3.or}</span>
								<div>
									<img src={img_visuBarcode} alt={''} />
									<span>{LANGUAGE[props.lang].computer.step3.barcode}</span>
									<div>{LANGUAGE[props.lang].computer.step3.search}</div>
								</div>
							</div>
							{/* <span style={{margin: 10}}>{LANGUAGE[props.lang].computer.step3.ocr}</span> */}
						</div>
					}

					{props.step === 4 &&
						<div className={'computerStep4'} style={style}>
							<div className={'computerStep1'}>
								<div className={'computerStep4Title'}>
									<div>{LANGUAGE[props.lang].computer.step4.type}</div>
								</div>
								<div className={'computerStep4Container'}>
									<div>
										<div className={'computerStep4Label active'} />
										<span>{LANGUAGE[props.lang].computer.step4.clasiq}</span>
									</div>
									<div>
										<div className={'computerStep4Label'} />
										<span>{LANGUAGE[props.lang].computer.step4.ald}</span>
									</div>
									<div>
										<div className={'computerStep4Label'} />
										<span>{LANGUAGE[props.lang].computer.step4.exception}</span>
									</div>
									<div>
										<div className={'computerStep4Label'} />
										<span>{LANGUAGE[props.lang].computer.step4.grippe}</span>
									</div>
									<div>
										<div className={'computerStep4Label'} />
										<span>{LANGUAGE[props.lang].computer.step4.other}</span>
									</div>
									<div>
										<div className={'computerStep4Checkbox'} />
										<span>{LANGUAGE[props.lang].computer.step4.archive}</span>
									</div>
								</div>
							</div>
							<div className={'computerStep1 flex'}>
								<div className={'computerStep4Title'}>
									<div>{LANGUAGE[props.lang].computer.step4.preview}</div>
									<div>
										<div className={'computerStep4TitleButton active'}><img src={img_iconScan} alt={'scan'} /></div>
										<div className={'computerStep4TitleButton active'}><img src={img_iconScanAdd} alt={'add'} /></div>
										<div className={'computerStep4TitleButton'}><img src={img_iconScanRefresh} alt={'refresh'} /></div>
										<div className={'computerStep4TitleButton active'}><img src={img_iconScanDelete} alt={'delete'} /></div>
									</div>
								</div>
								<div className={'computerStep4Container'}>
									<div>
										<img src={img_visuOrdonance} alt={'ordonnance'} />
									</div>
								</div>
							</div>
						</div>
					}

					{props.step === 5 &&
						<div className={'computerStep5'}>
							<div className={'computerStep1'}>
								<div className={'computerStep5Line'}>
									<div className='computerInput' style={{flex: 2}}>
										<span>{LANGUAGE[props.lang].computer.step5.fullname}</span>
										<div className={'computerInputContainer'}>{LANGUAGE[props.lang].computer.patient.doctorName.toUpperCase()}</div>
									</div>

									<div className={'computerStep5Icon'}>
										<div><img src={img_iconListbullet} alt={''} /></div>
										<div><img src={img_iconAdd} alt={''} /></div>
									</div>

									<div className='computerInput' style={{flex: 1}}>
										<span>{LANGUAGE[props.lang].computer.step5.speciality}</span>
										<div className={'computerInputContainer'}>
											{LANGUAGE[props.lang].computer.patient.doctorInfo}
										</div>
									</div>
								</div>

								<div className={'computerStep5Line'} style={{marginBottom: 30}}>
									<div className='computerStep5Date'>
										<span>{LANGUAGE[props.lang].computer.step5.precriptionDate}</span>
										<div className={`computerStep5DateInput ${step5 === 0 && 'shadow'}`}>
											<input
												ref={dateInput}
												readOnly={step5 !== 0}
												type={'date'}
												onClick={() => dateInput.current.showPicker()}
												min="2021-06-01"
												max="2022-06-13"
												onChange={e => {
													if (e.target.value !== '2022-06-13')
														return set_error('step5')
													set_error(null)
													set_step5(1)
												}} />
											{/* <div>{step5 === 0 ? <span>&nbsp;</span> : '19/05/2021'}</div> */}
											{/* <div><img src={img_iconCalendar} alt={'calendar'} /></div> */}
										</div>

										{(error === 'step5') &&
											<div className={'computerErrorPopup errorStep5'}>
												{LANGUAGE[props.lang].computer.step5.error}
											</div>
										}
									</div>

									<div className='computerInput' style={{flex: 1}}>
										<span>{LANGUAGE[props.lang].computer.step5.numero}</span>
										<div className={'computerInputContainer'} style={{backgroundColor: '#fff'}}>&nbsp;</div>
									</div>

									<div className='computerInput'>
										<span>{LANGUAGE[props.lang].computer.step5.number}</span>
										<div className={'computerInputContainer'}>&nbsp;</div>
									</div>

									<div className='computerInput' style={{flex: 1}}>
										<span>{LANGUAGE[props.lang].computer.step5.frequence}</span>
										<div className={'computerInputContainer'}>&nbsp;</div>
										<div className={'computerInputLabel'}>
											<div>
												<div className={'computerStep4Label active noBackground'} />
												<span>{LANGUAGE[props.lang].computer.step5.day}</span>
											</div>
											<div>
												<div className={'computerStep4Label'} />
												<span>{LANGUAGE[props.lang].computer.step5.month}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					}

					{props.step === 6 &&
						<div className={'computerStep6'}>
							<table>
								<thead>
									<tr>
										<th />
										<th width='40%'>{LANGUAGE[props.lang].computer.step6.product}</th>
										<th>{LANGUAGE[props.lang].computer.step6.price}</th>
										<th>{LANGUAGE[props.lang].computer.step6.quantity}</th>
										<th>{LANGUAGE[props.lang].computer.step6.amo}</th>
										<th>{LANGUAGE[props.lang].computer.step6.amc}</th>
										<th>{LANGUAGE[props.lang].computer.step6.rest}</th>
										<th />
									</tr>
								</thead>
								<tbody>
									<tr>
										<td colSpan={8} ><div className={'computerStep6Line'} /></td>
									</tr>
									{step6 === 1 &&
										<tr className={'computerStep6TableGreen'}>
											<td>{'>'}</td>
											<td>
												<div className={'computerStep6SandClock'}>
													{LANGUAGE[props.lang].computer.step6.billing}
													<img src={img_sandClock} alt={''} />
												</div>
											</td>
											<td></td>
											<td />
											<td>{'1,68 €'}</td>
											<td>{'0,00 €'}</td>
											<td>{'3,31 €'}</td>
											<td />
										</tr>
									}

									<tr>
										<td colSpan={8} ><div className={'computerStep6Line empty'} /></td>
									</tr>

									{step6 === 1 &&
										<tr className={'computerStep6TableLine'}>
											<td>
												<div><div className={'computerStep6Round'}>{'1'}</div></div>
											</td>
											<td className={'computerStep6TableLineStartBorder'}>
												<div className={'computerStep6Product'}>
													<div>{'>'}</div>
													<div>
														<div className={'computerStep6ProductText blue'}>{LANGUAGE[props.lang].computer.step6.productName}</div>
														<div className={'computerStep6RoundContainer'}>
															<div className={'computerStep6Round blue'}>{'2'}</div>
															<div className={'computerStep6Round green'}>{'G'}</div>
														</div>
													</div>
												</div>
											</td>
											<td style={{fontWeight: 'normal'}}>{'4,48 €'}</td>
											<td style={{fontWeight: 'normal'}}>
												<div className={'computerStep6Input'}>{'1'}</div>
											</td>
											<td style={{fontWeight: 'normal'}}>{'1,35 €'}</td>
											<td style={{fontWeight: 'normal'}}>{'0,00 €'}</td>
											<td style={{fontWeight: 'normal'}}>{'3,13 €'}</td>
											<td><img alt={''} /></td>
										</tr>
									}

									<tr>
										<td colSpan={8} ><div className={'computerStep6Line empty'} /></td>
									</tr>

									{step6 === 1 &&
										<tr className={'computerStep6TableLine'}>
											<td><div /></td>
											<td className={'computerStep6TableLineStartBorder'}>
												<div className={'computerStep6Product'}>
													<div>{'>'}</div>
													<div>{LANGUAGE[props.lang].computer.step6.honoraire}</div>
												</div>
											</td>
											<td>{'0,51 €'}</td>
											<td />
											<td>{'0,33 €'}</td>
											<td>{'0,00 €'}</td>
											<td>{'0,18 €'}</td>
											<td />
										</tr>
									}
								</tbody>
							</table>

							{(step6 === 0) &&
								<div className={'computerInfoPopup messageStep6'}>
									{LANGUAGE[props.lang].computer.step6.error}
								</div>
							}
						</div>
					}

					{props.step === 7 &&
						<div className={'computerStep7'}>
							<div className={'computerStep1'}>
								<div className={'computerStep4Title'}>
									<div>{LANGUAGE[props.lang].computer.step7.type}</div>
								</div>
								<div className={'computerStep4Container'}>
									<div>
										<div className={'computerStep7Add'} onClick={() => set_error('step7Add')}>
											<img src={img_iconAdd} />
										</div>
										<span>{LANGUAGE[props.lang].computer.step7.verso}</span>
									</div>
									<div>
										<div className={'computerStep7Add'} onClick={() => set_error('step7Add')}>
											<img src={img_iconAdd} />
										</div>
										<span>{LANGUAGE[props.lang].computer.step7.soin}</span>
									</div>
									<div>
										<div className={'computerStep7Add'} onClick={() => set_error('step7Add')}>
											<img src={img_iconAdd} />
										</div>
										<span>{LANGUAGE[props.lang].computer.step7.billing}</span>
									</div>
									<div>
										<div className={'computerStep7Add'} onClick={() => set_error('step7Add')}>
											<img src={img_iconAdd} />
										</div>
										<span>{LANGUAGE[props.lang].computer.step7.ordonnance}</span>
									</div>
									<div>
										<div className={'computerStep7Add'} onClick={() => set_error('step7Add')}>
											<img src={img_iconAdd} />
										</div>
										<span>{LANGUAGE[props.lang].computer.step7.attestation}</span>
									</div>
									<div>
										<div className={'computerStep7Add'} onClick={() => set_error('step7Add')}>
											<img src={img_iconAdd} />
										</div>
										<span>{LANGUAGE[props.lang].computer.step7.plan}</span>
									</div>
								</div>
								{(error === 'step7Add') &&
									<div className={'computerErrorPopup errorStep7Add'}>
										{LANGUAGE[props.lang].computer.step7.errorAdd}
									</div>
								}
							</div>
							<div className={'computerStep1 flex'}>
								<div className={'computerStep4Title'}>
									<div>{LANGUAGE[props.lang].computer.step7.list}</div>
								</div>
								<div className={'computerStep7Container'}>
									<div className={'computerStep7Line'}>
										<div>
											<div />
											<div className={'computerStep7Text'}>{LANGUAGE[props.lang].computer.step7.verso}</div>
											<div className={'computerStep7Button opacity'}>{LANGUAGE[props.lang].computer.step7.print}</div>
											<div className={'computerStep7Button white'}><img src={img_iconScanDelete} alt={''} /></div>
										</div>
									</div>

									{/* <div className={'computerStep7Line'}>
										<div>
											<div />
											<div className={'computerStep7Text'}>{LANGUAGE[props.lang].computer.step7.soin}</div>
											<div className={`computerStep7Button ${step7 === 0 && 'shadow'}`} onClick={() => step7 === 0 && set_step7(1)}>{LANGUAGE[props.lang].computer.step7.print}</div>
											<div className={'computerStep7Button white'}><img src={img_iconScanDelete} alt={''} /></div>
										</div>
										<div className={'computerStep7CheckContainer'}>
											<div>
												<input type={'checkbox'} readOnly checked={false} />
												<span>{LANGUAGE[props.lang].computer.step7.billingTier}</span>
											</div>
											<div>
												<input type={'checkbox'} checked readOnly/>
												<span>{LANGUAGE[props.lang].computer.step7.pay}</span>
											</div>
										</div>
									</div> */}
								</div>

								{error === 'step7Date' &&
								<div className={'computerErrorPopup errorStep7Date'}>
									{LANGUAGE[props.lang].computer.step7.errorDate}
								</div>
							}
							</div>
						</div>
					}


					{props.step !== 10 &&
						<div id={'bottom'} className={'computerContainerBottom'}>
							{(props.step <= 2 || (props.step >= 5 && props.step < 7)) &&
								<div className={'computerContainerBottom0'}>
									<div>{props.step <= 2 ? '0' : '1'}</div>
								</div>
							}

							<div className={'computerContainerBottomTotal'}>
								<span>{`${LANGUAGE[props.lang].computer.total} ${props.step <= 5 ? '0,00' : '3,31'}${LANGUAGE[props.lang].computer.money}`}</span>
								<img src={img_iconTrash} alt={''} />
							</div>

							<div className={'flex'} />

							{props.step === 3 &&
								<div className={'computerContainerBottomBox border'}>
									<span>{LANGUAGE[props.lang].computer.step3.noPrescription}</span>
								</div>
							}

							<div className={`computerContainerBottomBox ${props.step <= 2 && 'active'} ${((props.step === 1 && props.step1 === 1) || (props.step === 2 && step2 === 1)) && 'shadow'}`} onClick={() => ((props.step === 1 && props.step1 === 1) || (props.step === 2 && step2 === 1)) && props.set_step(props.step + 1)}>
								<span>
									{props.step <= 2 && LANGUAGE[props.lang].computer.step1.readCard}
									{props.step === 3 && LANGUAGE[props.lang].computer.step3.later}
									{props.step >= 4 && LANGUAGE[props.lang].computer.step4.previous}
								</span>
							</div>
							<div
								className={`computerContainerBottomBox ${((props.step === 3 && step3 === 1) || props.step === 4 || (props.step === 5 && step5 === 1)  || (props.step === 6 && step6 === 1) || props.step === 7) && 'shadow'} ${props.step > 2 && 'active'}`}
								onClick={() => {
									if ((props.step === 3 && step3 === 1) || props.step === 4 || (props.step === 5 && step5 === 1) || (props.step === 6 && step6 === 1))
										return props.set_step(props.step + 1)
									if (props.step === 7)
										return step7 === 2 ? props.set_step(props.step + 1) : set_step7(2)
								}}>
								<span>
									{props.step <= 2 && LANGUAGE[props.lang].computer.step1.readApCV}
									{props.step === 3 && LANGUAGE[props.lang].computer.step3.read}
									{props.step >= 4 && LANGUAGE[props.lang].computer.step4.valid}
								</span>

								{error === 'step6' &&
									<div className={'computerErrorPopup errorStep6'}>
										{LANGUAGE[props.lang].computer.step6.error}
									</div>
								}
							</div>
						</div>
					}
				</div>
			)
		}

		if (props.step === 8) {
			return (
				<div className={'computerMainContainer'}>
					<div className={'computerStep8Title'}>
						<span>{LANGUAGE[props.lang].computer.step8.title}</span>
					</div>

					<div className={'computerStep8'}>
						<table>
							<thead>
								<tr>
									<th />
									<th width='40%'>{LANGUAGE[props.lang].computer.step8.product}</th>
									<th>{LANGUAGE[props.lang].computer.step8.price}</th>
									<th>{LANGUAGE[props.lang].computer.step8.quantity}</th>
									<th width={'10%'} />
									<th>{LANGUAGE[props.lang].computer.total.toUpperCase()}</th>
									<th />
								</tr>
							</thead>
							<tbody>
								<tr className={'computerStep8Table'}>
									<td>{'>'}</td>
									<td>{LANGUAGE[props.lang].computer.step8.ordonnance}</td>
									<td />
									<td />
									<td>{LANGUAGE[props.lang].computer.step8.rest}</td>
									<td className={'computerStep8BluePrice'}>{'3,31€'}</td>
									<td />
								</tr>

								<tr>
									<td colSpan={8} ><div className={'computerStep8Line empty'} /></td>
								</tr>

								{props.step8 >= 2 &&
									<tr className={'computerStep8Table'}>
										<td style={{position: 'relative', display: 'flex', justifyContent: 'center'}}>
											<img src={img_iconGift_blue} alt={''} />
											{props.step8 === 3 &&
												<div className={`computerStep8Message`}>
													<div>
														<div>
															<div className={'computerErrorPopup messageStep7'}>
																{LANGUAGE[props.lang].computer.step8.message2}
															</div>
														</div>
													</div>
												</div>
											}
										</td>
										<td>{LANGUAGE[props.lang].computer.step8.gel}</td>
										<td>{'7,95€'}</td>
										<td style={{fontWeight: 'normal'}}>
											<div className={'computerStep6Input'}>{'1'}</div>
										</td>
										<td />
										<td className={'computerStep8BluePrice'}>{'7,95€'}</td>
										<td />
									</tr>
								}

								<tr>
									<td colSpan={8} ><div className={'computerStep8Line empty'} /></td>
								</tr>

								{props.step8 >= 4 &&
									<tr className={'computerStep8Table'}>
										<td><img src={img_iconGift_blue} alt={''} /></td>
										<td>{LANGUAGE[props.lang].computer.step8.creme}</td>
										<td>{'15,90€'}</td>
										<td style={{fontWeight: 'normal'}}>
											<div className={'computerStep8Input'}>
												<div onClick={() => props.step8 >= 5 && set_error('promo')}>{'-'}</div>
												<span>{props.step8 === 4 ? '1' : '2'}</span>
												<div className={`computerStep8PlusInput ${props.step8 === 4 && 'active'}`} onClick={() => props.step8 === 4 ? props.set_step8(6) : set_error('promo')}>{'+'}</div>
											</div>
										</td>
										<td style={{position: 'relative'}}>
											<div className={'computerStep8PromoIcon'}>
												<img src={img_promo} alt={''} />
												{/* <span>{'PROMO'}</span> */}
											</div>

											{props.step8 === 6 &&
												<div className={'computerInfoPopup step8Message4'}>
													{LANGUAGE[props.lang].computer.step8.message4}
												</div>
											}
											{error === 'promo' &&
												<div className={'computerErrorPopup step8Message4'}>
													{LANGUAGE[props.lang].computer.step8.error2}
												</div>
											}
										</td>
										<td className={'computerStep8BluePrice'}>{props.step8 <= 4 ? '15,90€' : '21,90€'}</td>
										<td />
									</tr>
								}
							</tbody>
						</table>
					</div>

					{props.step8 === 4 &&
						<div className={'step8PopupContainer'}>
							<div>
								<div className={'computerInfoPopup step8Message3'}>
									{LANGUAGE[props.lang].computer.step8.message3}
								</div>
							</div>
							<div className={'step8Popup'}>
								<div className={'step8PopupHeader'}>
									<div>{LANGUAGE[props.lang].computer.step8.creme}</div>
									<div>
										<div>{LANGUAGE[props.lang].computer.step8.promo}</div>
										<div>{LANGUAGE[props.lang].computer.step8.stock}</div>
									</div>
								</div>

								<table>
									<thead>
										<tr>
											<th width={'60%'}>{LANGUAGE[props.lang].computer.step8.promoProduct}</th>
											<th>{'PRIX TTC'}</th>
											<th>{'PROMO TTC'}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td style={{textAlign: 'left'}}>{`${LANGUAGE[props.lang].computer.step8.creme} ${LANGUAGE[props.lang].computer.step8.lot} 03236812351651`}</td>
											{/* <td>{'15,90'}</td>
											<td>{'15,90'}</td> */}
											<td style={{color: 'red', textDecoration: 'line-through'}}>{'31,80'}</td>
											<td style={{color: '#00AAFF'}}>{'21,90'}</td>
										</tr>
										{/* <tr>
											<td />
											<td style={{color: 'red', textDecoration: 'line-through'}}>{'15,90'}</td>
											<td style={{color: '#00AAFF'}}>{'21,90'}</td>
										</tr> */}
									</tbody>
								</table>
							</div>
						</div>
					}

					{props.step8 === 1 &&
						<div className={'computerInfoPopup step8Message1'}>
							{LANGUAGE[props.lang].computer.step8.message1}
						</div>
					}

					{error === '2product' &&
						<div className={'computerErrorPopup step8Error1'}>
							{LANGUAGE[props.lang].computer.step8.error1}
						</div>
					}

					{props.step8 === 6 &&
						<div className={'computerErrorPopup step8Error1'}>
							{LANGUAGE[props.lang].computer.step8.error3}
						</div>
					}
					{error === 'remise' &&
						<div className={'computerErrorPopup step8Error1'}>
							{LANGUAGE[props.lang].computer.step8.error4}
						</div>
					}
				</div>
			)
		}
	}

	function	renderStep9() {
		return (
			<div className={'computerMainStep9'}>
				<div className={'computerStep9Title'}>{LANGUAGE[props.lang].computer.step9.paiement}</div>

				<div className={'computerStep9Container'}>
					<span>{LANGUAGE[props.lang].computer.step9.paiement}</span>

					<img src={img_iconCb} alt={''} />

					<div className={'computerStep9Facture'}>
						<div>{LANGUAGE[props.lang].computer.step9.total}</div>
						<span>{'33,16€'}</span>
					</div>

					<div className={'computerStep9Option'}>
						<img src={img_iconPrint_blue} alt={''} />
						<span>{LANGUAGE[props.lang].computer.step9.option}</span>
					</div>

					<div className={'computerStep9ButtonContainer'}>
						<div className={'computerStep9Button'}>
							<span>{LANGUAGE[props.lang].computer.step9.cancel}</span>
						</div>
						<div className={`computerStep9Button blue ${step9 === 1 && 'shadow'}`} onClick={() => step9 === 1 && props.set_step(10)}>
							<img src={img_iconSave} alt={''} />
							<span>{LANGUAGE[props.lang].computer.step9.save}</span>

							{/* {step9 === 1 &&
								<div className={`computerStep9Message`}>
									<div />
								</div>
							} */}
							{step9 === 1 &&
								<div className={`computerInfoPopup step9Message`}>
									{LANGUAGE[props.lang].computer.step9.message}
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}

	if (props.step === 9)
		return renderStep9()

	let	total = '3,31'

	if (props.step8 >= 2)
		total = '11,26'
	if (props.step8 >= 4)
		total = '27,16'
	if (props.step8 >= 5)
		total = '33,16'	

	return (
		<div className={`computerMain ${props.step === 10 && 'blur'}`} style={props.style} onClick={() => {
			if (error)
				return set_error(false)
		}}>
			<div className={'computerLeft'}>
				<div id={'computerHeader'} className={'computerHeader'}>
					<div className={`computerMenuCase ${props.step <= 7 ? 'green' : 'blue'}`}>
						<img src={img_logoSmartRx} alt={'nev'} />
					</div>
					{(props.step <= 2 || (props.step >= 5 && props.step < 7)) &&
						<div className={`computerMenuCase ${(props.step !== 6) && 'green'}`}>
							{props.step <= 2 && <img src={img_iconPatient} alt={''} />}
							{props.step === 5 && <div style={{backgroundImage: `url('${img_stethoscope_white}')`}} />}
							{props.step === 6 && <div style={{backgroundImage: `url('${img_settings}')`}} />}
						</div>
					}
					{(props.step === 6) &&
						<div className={`computerMenuCase green`}>
							<div style={{backgroundImage: `url('${img_capsule}')`}} />
						</div>
					}

					{(props.step === 8) &&
						<div className={`computerMenuCase`}>
							<div style={{backgroundImage: `url('${img_iconGift}')`}} />
						</div>
					}
					{(props.step === 8) &&
						<div className={`computerMenuCase`}>
							<div style={{backgroundImage: `url('${img_iconMan}')`}} />
						</div>
					}
					{(props.step === 8) &&
						<div className={`computerMenuCase blue`}>
							<div style={{backgroundImage: `url('${img_capsule}')`}} />
						</div>
					}

					{(props.step <= 2 || (props.step >= 5 && props.step < 7) || props.step === 8) &&
						<div className={'computerHeaderSearch'} >
							<div>
								<img src={props.step >= 8 ? img_iconSearch : img_iconSearch_green} alt={''} />
							</div>
							{props.step <= 2 && <span>{LANGUAGE[props.lang].computer.header.search}</span>}
							{props.step === 5 && <span>{LANGUAGE[props.lang].computer.header.searchStep5}</span>}
							{props.step === 6 && <span>{LANGUAGE[props.lang].computer.header.searchStep6}</span>}
							{props.step === 8 && <span>{LANGUAGE[props.lang].computer.header.searchStep8}</span>}
						</div>
					}

					{props.step <= 2 &&
						<div className={`computerMenuCase white`}>
							<img src={img_iconClose} alt={''} />
						</div>
					}
				</div>

				<div className={'computerBody'}>
					<div className={'computerMenu'}>
						<div className={`computerMenuCase ${props.step === 8 ? 'blue' : 'greyText'}`}>
							{props.step >=3 ?
								<div style={{backgroundImage: `url('${props.step === 8 ? img_iconComptoirWhite : img_iconComptoir}')`}} />
								:
								<img src={img_iconComptoirBlue} alt={''} />
							}
							<span>{LANGUAGE[props.lang].computer.menu.comptoir}</span>
						</div>

						<div className={`computerMenuCase ${props.step < 8 ? 'green' : 'greenText'}`} onClick={() => props.step === 8 && props.set_step(7)} >
							<div style={{backgroundImage: `url('${props.step < 8 ? img_iconOrdonnance : img_iconOrdonnanceGreen}')`}}>
								{/* <img src={img_iconOrdonnance} alt={''} /> */}
							</div>
							<span>{LANGUAGE[props.lang].computer.menu.ordonnance}</span>
						</div>

						{(props.step >= 3) &&
							<div className={`computerMenuCase greyText`}>
								<div style={{backgroundImage: `url('${img_iconMan}')`}} />
								<span>{LANGUAGE[props.lang].computer.menu.customer}</span>
							</div>
						}
						{(props.step >= 3 && props.step < 8) &&
							<div className={`computerMenuCase greyText`}>
								<div style={{backgroundImage: `url('${img_iconTherapeutique}')`}} />
								<span>{LANGUAGE[props.lang].computer.menu.therapeutique}</span>
							</div>
						}
						{(props.step >= 5 && props.step <= 6) &&
							<div className={`computerMenuCase greyText`}>
								<div style={{backgroundImage: `url('${img_stethoscope}')`}} />
								<span>{LANGUAGE[props.lang].computer.menu.medecin}</span>
							</div>
						}
						{(props.step >= 3 && props.step < 8) &&
							<div className={`computerMenuCase greyText`}>
								<div style={{backgroundImage: `url('${img_folderFirstAid}')`}} />
								<span>{LANGUAGE[props.lang].computer.menu.comptoir}</span>
							</div>
						}
						

						<div className={'flex'} />

						<div className={`computerMenuBottom`}>
							<img src={img_iconBcb} alt={'bcb'} />
							{/* <img src={img_iconHelp} alt={'help'} />
							<img src={img_iconKeyboard} alt={'keyboard'} />
							<img src={img_iconChat} alt={'chat'} /> */}
						</div>
					</div>
					{renderMain()}
				</div>
			</div>

			<PatientMenu {...props} set_error={set_error} total={total} />
		</div>
	)
}

export default Computer