import { useState, useEffect }		from 'react'


function Legal(props) {
	return (
		<div className={'appModal'}>
			<div className={'legal'}>
				<div onClick={() => props.close()}>X</div>
				<div>CONDITIONS GENERALES D'UTILISATION</div>

				Le site « https://www.smartrealxperience.fr/» est la propriété de de la société SMART RX, SAS au capital social de 46 436 090 € – RCS Nanterre 342 280 609 dont le siège social est 137, rue d'Aguesseau - 92100 BOULOGNE-BILLANCOURT, France;<br /><br />
				L'objectif de ce site est de fournir des informations sur le Nouvel Espace de Vente du logiciel de gestion d’officine commercialisé par SMART RX, en proposant un aperçu d’une expérience de navigation dans ce logiciel, en s’appuyant sur un cas patient d’officine factice. Cette interface propose une navigation partielle, n’ayant en aucun cas pour objectif de reproduire dans sa totalité les aspects graphiques, informatiques, fonctionnels du logiciel « NEV ».<br /><br />
				En fin d’expérience, le site propose un formulaire d’inscription à une ou plusieurs sessions interactives de découverte du logiciel avec un ingénieur commercial de SMART RX. Les champs d’inscriptions demandés sont détaillés ci-dessous dans la partie POLITIQUE DE GESTION DES DONNEES PERSONNELLES<br /><br />
				La structure générale, ainsi que les logiciels, textes, images animées ou non, la technologie, le savoir-faire, et tous autres éléments composant le présent site sont la propriété exclusive de SMART RX, ses sociétés mère, sœurs ou filiales, ou appartiennent à des tiers ayant autorisé limitativement SMART RX à les utiliser.<br /><br />
				Toute représentation ou reproduction totale ou partielle de ce site par quelque procédé que ce soit, sans l'autorisation expresse de SMART RX est interdite et constituerait une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle.<br /><br />
				Les marques de SMART RX et de ses sociétés mères, sœurs et affiliées ainsi que les logos figurant sur le site sont des marques déposées et sont donc la propriété de SMART RX et de ses sociétés mères, sœurs et affiliées et/ou celles de leurs partenaires. Toute reproduction totale ou partielle de ces marques ou de ces logos effectués sans l'autorisation expresse de SMART RX est donc prohibée, au sens des articles L.713-2 et L.713-3 du Code de la propriété intellectuelle.<br /><br />
				SMART RX ne saurait garantir l´exactitude et l´exhaustivité des informations contenues sur ce site. SMART RX se réserve par ailleurs le droit de changer sans avertissement préalable les contenus de ce site sans que sa responsabilité puisse être engagée.<br /><br />
				SMART RX ne saurait garantir l’exactitude, l’exhaustivité et l’adéquation des informations et éléments du site avec vos besoins professionnels ou personnels. SMART RX n’apporte aucune garantie quant au fonctionnement du présent site de manière performante et ininterrompue.<br /><br />
				SMART RX n´est en outre en aucun cas responsable des dommages directs ou indirects, matériels ou immatériels de quelque nature lié à l'utilisation même partielle des informations contenues sur le présent site.<br /><br />
				SMART RX n´est en outre en aucun cas responsable des dommages directs ou indirects, matériels ou immatériels de quelque nature lié aux téléchargements, au contenu des documents ou programmes à télécharger et à l'utilisation même partielle des informations contenues sur les documents ou programmes téléchargés via le présent site.<br /><br />
				SMART RX rappelle qu’il vous est recommandé de procéder à des sauvegardes régulières (si possible quotidiennes) de vos données et que vous avez la responsabilité de maintenir votre installation informatique en bon état de fonctionnement de même que votre connexion internet. SMART RX ne dispose en outre d´aucun contrôle quant au contenu des sites tiers référencés par des liens hypertextes et ne saurait en conséquence assumer une quelconque responsabilité de ce fait.<br /><br />
				Ni SMART RX ni toute autre partie impliquée dans la production, l'approvisionnement, le design ou la maintenance de toute ou partie de cette présentation internet, ou des pages, n'est responsable de quelque manière que ce soit des dommages directs ou indirects, ou de dommages avec ou sans faute qui pourraient survenir à l'issue de l'accès, de l'utilisation, ou de l'incapacité d'utilisation de la présentation internet de SMART RX ou des présentations auxquelles elles sont liées.<br /><br />

				<div>MENTIONS LEGALES</div>
				<center><b><u style={{fontSize: 12}}>INFORMATIONS EDITEUR</u></b></center><br />
				<b>Le présent site est édité par la société Smart RX</b>, SAS au capital social de 46 436 090 euros immatriculé au RCS sous le numéro 342 280 609<br /><br />
				<b>Siège social</b> : 137, Rue d’Aguesseau, 92100 BOULOGNE-BILLANCOURT<br /><br />
				<b>Téléphone</b> : 01 49 09 22 00<br /><br />
				<b>Directeur de la publication</b> : Anne Sophie BOUY, Directeur Général<br /><br /><br />

				<center><b><u style={{fontSize: 12}}>INFORMATIONS HEBERGEUR</u></b></center><br />
				Ce site est hébergé par la société OVH, société par action simplifiée, dont le siège social est situé au 2 rue Kellermann, 59100 Roubaix.<br /><br />
				Téléphone : +33 9 72 10 10 07<br /><br />
				https://www.ovh.com/fr/<br /><br /><br />

				<div>POLITIQUE DE PROTECTION DES DONNEES PERSONNELLES</div>
				<b>LA PROTECTION DES DONNEES PERSONNELLES EST UNE DES VALEURS CLE DE SMART RX ET A CE TITRE, NOUS PRENONS LES MESURES NECESSAIRES A LA PROTECTION DES DONNEES PERSONNELLES QUI NOUS SONT CONFIEES PAR NOS CLIENTS DANS LEUR NAVIGATION SUR CE SITE. <br /><br /><br />
				VOS DONNEES SONT HEBERGEES EN FRANCE AUPRES DE LA SOCIETE OVH, SOCIETE PAR ACTION SIMPLIFIEE, DONT LE SIEGE SOCIAL EST SITUE AU 2 RUE KELLERMANN, 59100 ROUBAIX</b><br /><br />

				<b style={{fontSize: 15.5}}>Une conformité native au RGPD</b><br />
				Le Site est administré en conformité avec le Règlement (UE) n°2016/679 relatif à la protection des données personnelles (ci-après « RGPD »), les dispositions de la loi informatique et libertés du 6 janvier 1978 telle que modifiée en vigueur.<br /><br /><br />

				<b style={{fontSize: 15.5}}>Un Hébergement sécurisé</b><br />
				L'hébergement du site est réalisé par la société OVH, société par action simplifiée, dont le siège social est situé au 2 rue Kellermann, 59100 Roubaix, Téléphone : +33 9 72 10 10 07, https://www.ovh.com/fr/<br /><br /><br />

				<b style={{fontSize: 15.5}}>Vous souhaitez connaître la manière dont vos données à caractère personnel sont traitées via notre site?</b><br />
				<b>La présente Politique de protection des données personnelles (ci-après la « Politique ») a pour objet de donner des précisions sur les finalités/les raisons pour lesquelles nous collectons vos données sur notre site     « https://www.smartrealxperience.fr/» (ci-après dénommé le « Site »), la façon dont nous les utilisons ainsi que les droits dont vous disposez.</b><br /><br />
				La présente Politique complète et fait partie intégrante des Conditions Générales d’Utilisation du Site disponibles ici. L’utilisation du Site implique votre acceptation aux dispositions des Conditions Générales d’Utilisation et à la présente Politique.<br /><br />
				Cette Politique peut être modifiée, complétée ou mise à jour afin notamment de se conformer à toute évolution légale, réglementaire, jurisprudentielle et technique. Nous vous invitons à consulter régulièrement cette Politique afin de vous tenir informé de la version en vigueur.<br /><br /><br />

				<b style={{fontSize: 15.5}}>QUI SONT LES RESPONSABLES DE TRAITEMENT ?</b><br />
				NB : Le responsable d'un traitement de données à caractère personnel, au sens du RGPD, est la personne, l'autorité publique, la société ou l'organisme qui détermine les finalités et les moyens de ce fichier, qui décide de sa création.<br /><br />
				Smart RX est responsable de traitement dans le cadre de la gestion et administration du Site ainsi que dans le cadre de la navigation.<br /><br /><br />
				<b style={{fontSize: 15.5}}>LA SECURITE DE VOS DONNEES EST UNE PRIORITE POUR SMART RX. <br /><br />
				SMART RX PREND LES MESURES PROPRES A ASSURER LA PROTECTION ET LA CONFIDENTIALITE DES DONNEES PERSONNELLES QU’ELLE DETIENT OU QU’ELLE TRAITE DANS LE RESPECT DES DISPOSITIONS LEGALES ET REGLEMENTAIRES EN VIGUEUR (Loi Informatique et Libertés LIL ET RGPD)</b><br /><br /><br />

				<b style={{fontSize: 15.5}}>QUELLES SONT LES DONNEES PERSONNELLES COLLECTEES ?</b><br />
				Les Données Personnelles susceptibles d’être collectées par Smart RX en tant que responsable de traitement sont notamment les suivantes :<br />
				&nbsp;&nbsp;&nbsp;&nbsp;○ vos nom et prénom<br />
				&nbsp;&nbsp;&nbsp;&nbsp;○ votre adresse postale<br />
				&nbsp;&nbsp;&nbsp;&nbsp;○ votre adresse e-mail<br />
				&nbsp;&nbsp;&nbsp;&nbsp;○ votre numéro FINESS<br />
				&nbsp;&nbsp;&nbsp;&nbsp;○ dénomination de la Pharmacie<br />
				&nbsp;&nbsp;&nbsp;&nbsp;○ nom du groupement<br />
				&nbsp;&nbsp;&nbsp;&nbsp;○ vos données de connexion et d’utilisation du Site<br />
				&nbsp;&nbsp;&nbsp;&nbsp;○ votre Adresse IP<br /><br /><br />

				<b style={{fontSize: 15.5}}>POURQUOI CES DONNEES SONT-ELLES COLLECTEES?</b><br />
				Sur la base de votre accord, vos Données Personnelles peuvent être collectées et traitées par Smart RX en tant que responsable de traitement :<br />
				● dans le cadre de l’administration du Site<br />
				● pour traiter votre demande lorsque vous nous contactez via notre formulaire de contact ;<br />
				● dans le cadre de la gestion de nos cookies ;<br />
				● pour améliorer la navigation sur le Site, la qualité des services proposés;<br />
				● pour gérer les demandes relatives à vos droits ;<br />
				● pour réaliser des statistiques notamment sur les activités réalisées sur le Site;<br />
				● pour prévenir et lutter contre la fraude informatique.<br />
				● A des fins de conformité légale et réglementaire<br />
				● A des fins d’information sur nos produits (notamment sur les opérations promotionnelles, nouveautés …) de réalisation d’enquêtes marketing (notamment de satisfaction), d’organisation jeux concours ou de réalisation d’opérations de mécénat. <br /><br />
				Ces données sont conservées conformément à la législation en vigueur et sont destinées notamment aux services du Smart RX (commercial, marketing, conformité, etc.). Les Données traitées sont susceptibles d’être transmises à nos sociétés Affiliées et le cas échéant à l’international dans le respect de la réglementation sur la protection des données personnelles.<br /><br /><br />

				<b style={{fontSize: 15.5}}>QUELS SONT LES DESTINATAIRES DES DONNEES PERSONNELLES COLLECTEES ?</b><br />
				Les destinataires de certaines de vos Données Personnelles sont :<br />
				● Notre hébergeur de données OVH ;<br />
				● Le cas échéant, les personnes autorisées chez nos sous-traitants ou partenaires, dans des conditions de stricte confidentialité et exclusivement afin de réaliser les finalités de traitement exposées par la présente Politique.<br /><br /><br />

				<b style={{fontSize: 15.5}}>COMBIEN DE TEMPS SONT CONSERVEES VOS DONNEES PERSONNELLES ?</b><br />
				Lorsqu’est utilisé notre formulaire de contact, les Données Personnelles collectées sont conservées le temps strictement nécessaire pour traiter votre demande et vous apporter une réponse.   <br /><br />
				Au-delà de ces délais, nous supprimerons ou archiverons ces Données Personnelles conformément aux dispositions légales ou réglementaires en vigueur.    <br /><br /><br />

				<b style={{fontSize: 15.5}}>QUELS SONT VOS DROITS ET RECOURS ?</b><br />
				Conformément à la réglementation en vigueur, vous disposez d’un droit d’accès à vos Données Personnelles, de rectification et d’effacement ainsi que d’un droit à la limitation du traitement, d’un droit à la portabilité et d’un droit d’opposition.Vous disposez également du droit de définir vos directives relatives à la conservation, à l’effacement et à la communication de vos Données Personnelles en cas de décès.<br />
				Pour exercer ces droits, vous pouvez nous contacter par courriel à l’adresse suivante : cnil.smart-rx@smart-rx.com ou par courrier à l’adresse suivante : Smart RX – 137 rue d’Aguesseau – 92100 BOULOGNE-BILLANCOURT. Vous devrez indiquer les Données Personnelles que vous souhaitez corriger, rectifier ou effacer. Nous ferons le nécessaire pour répondre de manière satisfaisante à vos demandes et conformément à la législation et réglementation en la matière.  <br />      
				Par ailleurs, vous pouvez introduire une réclamation auprès de la CNIL, autorité de contrôle en matière de protection des données à caractère personnel en France.<br /><br /><br />

				<b style={{fontSize: 15.5}}>QUELLE EST LA POLITIQUE DE SMART RX EN MATIERE DE COOKIES ?</b><br />
				Lors de la consultation de notre Site, des cookies sont susceptibles d’être déposés et enregistrés sur votre terminal (ordinateur, tablette, smartphone, etc.).<br /><br />
				La présente Politique en matière de cookies a pour objet de préciser les différents types de cookies et autres technologies similaires pouvant être utilisées sur notre Site.<br />
				Un cookie est un petit fichier texte qu’un site internet est susceptible de déposer sur le disque dur de votre terminal. Il enregistre des informations relatives à la navigation de votre terminal sur le site (telles que par exemple les pages visitées, la date et l’heure de la consultation. etc.) et sont installés sur le navigateur.<br /><br />

				<center><b>Pourquoi utilisons-nous des cookies ?</b></center><br />
				Les cookies nous permettent d’améliorer la navigation sur le site, d’optimiser et de personnaliser nos services sur le site, ainsi que de mesurer les audiences afin de vous proposer un site internet qui corresponde à vos attentes et préférences.Les cookies que nous utilisons sur notre site internet sont soit émis par nos soins, soit émis par des sociétés tierces pour notre compte.<br />
				Les cookies nécessaires à la fourniture des services et l’amélioration des performances du Site ne requièrent pas votre accord préalable. Plus précisément, ces cookies permettent notamment d’offrir à l’utilisateur un accès à son compte ou tout autre espace réservé via ses identifiants, de mémoriser les informations relatives, par exemple, à un formulaire que vous avez rempli ou à un service, de mettre en œuvre des mesures de sécurité, de mémoriser les préférences d’affichage de votre navigateur et d’adapter la présentation du site.Notre Site est susceptible de contenir des cookies de mesures d’audience émis par des tiers nous permettant d’établir des statistiques de fréquentation et d’utilisation des divers éléments composant le site en vue d’améliorer l’intérêt et l’ergonomie des Services proposés.<br />   
				Si vous ne souhaitez pas recevoir de cookies de la part de notre site internet, vous nous faire part de vos choix via le panneau de gestion des cookies disponible sur le site.   <br /><br />
				<b>La durée de conservation de ces cookies est de six (06) mois maximum</b> après leur premier dépôt dans le terminal de l’utilisateur, faisant suite à l’expression du consentement de ce-dernier.     <br />
				Vous pouvez gérer et modifier à tout moment l’utilisation des cookies via votre navigateur. Nous vous rappelons que la désactivation des cookies internes ou tiers ayant une finalité strictement nécessaire à la fourniture du service peut avoir pour conséquence de ralentir et/ou perturber l’accès et l’utilisation du site.   <br />
				Pour plus d’informations sur les cookies et la manière de les gérer dans votre navigateur, nous vous invitons à consulter les informations mises à disposition par votre navigateur ou encore la page suivante : https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser.<br /><br /><br />
			</div>
		</div>
	);
}

export default Legal;